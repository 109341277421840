
$title-font-family:	"almaq-rough";
$second-title-font-family:	"almaq-refined";
$third-title-font-family:	"adelle";
$standard-font-family: "proxima-nova";

$container: 1070;
$smll-container: 940;

$black: #333;
$blue: #4EB6CD;
$pink: #DD3965;
$grey: #545454;
$purple: #75718F;
$yellow: #EFCF40;


$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
