header.page-header {
  position: absolute;
  right:0;
  top:0;
  width:100%;
  z-index: 1000;
  height:129px;
  transition: right 0.3s ease;
  &:after {
    content: " ";
    height:129px;
    width:100%;
    position: absolute;
    right:0;
    top:0;
    background:url('../imgs/header.png');
    opacity:0.83;
    z-index: 200;
  }
  .page-header-inner {
    display:flex;
    max-width:1195px;
    margin:0 auto;
    position: relative;
    z-index: 300;
    width:90%;
    .col:nth-child(2) {
      flex-grow: 1;
      text-align:right;
      @media(max-width:1024px) {
        display:none;
      }
    }
    a#logo {
      background:#000000;
      display: inline-block;
      padding:25px 13px;
      position: relative;
      margin-right: 20px;
      &:after {
        content:" ";
        width:100%;
        height:25px;
        background:url('../imgs/logo_overlay.png') repeat-x bottom center;
        display:block;
        position: absolute;
        left:0;
        bottom:-25px;
      }
      
     
    }

    .logocol{
      max-width: 210px;
      width:18%;
      min-width: 120px;
      
      
    }
    a#logoTwo {
      background:#000000;
      display: inline-block;
      padding:25px 13px;
      position: relative;
      margin-left: 20px;
      &:after {
        content:" ";
        width:100%;
        height:25px;
        background:url('../imgs/logo_overlay.png') repeat-x bottom center;
        display:block;
        position: absolute;
        left:0;
        bottom:-25px;
      }
    }
    ul#nav {
      margin:20px 0;
      @media(max-width:1024px) {
        display:none;
      }
      li {
        display:inline-block;
        font-style: normal;
        margin-left:10px;
        text-transform: uppercase;
        font-family: $second-title-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        &:nth-child(1) {
          margin-left:0;
        }
        a {
          color:white;
          display:inline-block;
        }
        &:last-child() {
          a {
            background:$blue;
            color:white;
            padding:8px 18px 10px;
            letter-spacing: 1px;
            text-shadow: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
        &.active {
          a {
            color:$blue;
            &[href="./book"] {
              background:$blue;
              color:#fff;
            }
          }
        }
      }
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  border-radius:4px;
  cursor: pointer;
  display:none;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  z-index: 100;
  padding-top:2px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:10px;
  color:$blue;
  @media(max-width:1024px) {
    display: inline-block;
  }
  i {
    font-size:32px;
    position: relative;
    top:17px;
    @media(max-width:1024px) {
      font-size:30px;
      top:5px;
    }
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:1024px) {
    z-index: 9999;
    position: fixed;
    right:20px;
    top:15px;
  }
  @media(max-width:600px) { top:15px; right:15px; }
  @media(max-width:450px) { top:10px; right:10px; }
  &.menu-open {
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
