
form {
	width:100%;
}
fieldset {
	padding-bottom:50px;
	display:block;
	p.subtxt {
		font-size: 14px;
		color: #75718F;
		letter-spacing: 0;
		line-height: 1.5;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 10px;
		@media(max-width:600px) { font-size:13px; }
		@media(max-width:450px) { font-size:12px; }
	}
	&.highlight {
		background: #FDFFE0;
		padding:35px 30px 30px;
		margin-bottom: 30px;
		legend {
			position: relative;
			top:30px;
		}
		p {
			font-size: 12px;
			color: #6B6B6B;
			line-height: 16px;
			margin-bottom:20px;
			a {
				color:$pink;
				text-decoration: underline;
			}
		}
		div {
			line-height:26px;
			position: relative;
			margin-bottom:10px;
		}
	}
}
legend {
	font-family:$title-font-family;
	font-size: 17px;
	color: #FC5F92;
	letter-spacing: 0;
	font-style: normal;
	font-weight: 900;
	text-transform: uppercase;
	display: block;
	margin-bottom:10px;
	@media(max-width:900px) { font-size:16px; }
	@media(max-width:600px) { font-size:15px; }
	@media(max-width:450px) { font-size:14px; }
}
input, textarea {
	font-family:$standard-font-family;
	width:100%;
  border-radius: 4px;
	padding:13px;
	margin-top:5px;
	margin-bottom:5px;
	background: #FFFFFF;
	border: 1px solid #C8C8C8;
	font-size: 14px;
	color: #535353;
	letter-spacing: 0;
	line-height: 1;
	font-weight:400;
	-webkit-appearance: none;
}
label {
	display: none;
	&.show {
		display:flex;
	}
	&.chk_label {
		display: block;
		position: relative;
		&:active {
			top:1px;
		}
		input {
			width:auto;
			display:none;
		}
		span {
			display:block;
			border: 1px solid #C8C8C8;
			border-radius: 4px;
			padding:13px 13.5px;
			font-family:$standard-font-family;
			font-size: 14px;
			color: #6B6B6B;
			line-height: 1.4;
			position: relative;
			font-style: normal;
			font-weight: 400;
			cursor: pointer;
			height:100%;
			margin-bottom:15px;
		}
		input[type=checkbox]:checked + span {
			color:#20CADE;
			font-weight: 700;
			border:2px #20CADE solid;
		}
	}
}

.chk_lbl_wrap {
	display: flex;
	flex-wrap: wrap;
	label.chk_label {
		width:calc(50% - 7.5px);
		margin-bottom:15px;
		span {
			font-size: 12px;
			margin-bottom:0;
		}
		&:nth-child(odd) {
			margin-right:15px;
		}
	}
	@media(max-width:400px) {
		flex-direction: column;
		label.chk_label {
			width:calc(100%);
			margin-bottom:15px;
			&:nth-child(odd) {
				margin-right:0;
			}
		}
	}
}
textarea {
	min-height:224px
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance:none;
}

button {
	color:#fff;
	font-family:$title-font-family;
	text-transform: uppercase;
	padding:10px 25px;
	
	background:#F8A944;
	border-radius: 4px;

	font-size: 17px;
	color: #FBFBFB;
	letter-spacing: 1px;
	line-height: 27px;
	font-weight:700;
	width:100%;
}

#validation-msg {
	padding:25px;
	color:#333;
	margin:10px auto;
	width:100%;
	font-family:$title-font-family;
	font-weight:800;
	text-align:center;
	text-transform: uppercase;
	background:rgba(241, 255, 0, 0.12);
	a {
		color:$pink;
		text-decoration: underline;
	}
}

.styled-checkbox {
  position: absolute; // take it out of document flow
	opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
		padding: 0;
		font-size:12px;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 26px;
    height: 26px;
		background: white;
		border:1px #D8D8D8 solid;
		border-radius:4px;
  }

  // Box hover
  &:hover + label:before {
    background: #f35429;
  }

  // Box checked
  &:checked + label:before {
    background: #f35429;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 12px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.select_style {
	overflow: hidden;
	display: inline-block;
	position: relative;
	cursor: pointer;
  background: #fff;
  border-radius: 4px;
  font-family:$standard-font-family;
  font-size: 14px;
  width:100%;
	position:relative;
	border: 1px solid #C8C8C8;
	//margin-bottom:9px;
	font-weight:400;
  @media (max-width:900px) { font-size:15px; line-height: 1em; }
  @media (max-width:600px) { font-size:14px; line-height: 1em; }
  @media (max-width:450px) { font-size:12px; line-height: 1em; }
	&:after {
		content: "\f0dd";
		font-family: FontAwesome;
		position: absolute;
		top:0;
		right:0;
		width:50px;
		height:100%;
		line-height: 100%;
		display: block;
		text-align:center;
		font-size:27px;
		border-left:1px solid #C8C8C8;
		pointer-events: none;
		background:#fff;

	}
	select {
	  -moz-appearance: none;
	  -webkit-appearance: none;
		appearance:none;
		background:none;
		background:transparent;
		border:none;
		cursor:pointer;
		outline:none;
		padding:10.5px 13px;
	  width:100%;
		option { padding-right:45px; }
	}
}

.error-input,
div.error-input {
	border:1px red solid;
}
.error-msg {
	font-size:14px;
	padding:5px 0;
	display: none;
	color:red;
	text-align:right;
}

.form-wrap {
	background:url('../imgs/footer_img.jpg') center no-repeat;
	background-size:cover;
	color:#fff;
	padding:60px;
	text-align:center;
	.inner-wrap {
		max-width:932px;
		margin:0 auto;
		width:100%;
		h3 {
			font-size: 26px;
			color: #FFFFFF;
			font-weight:800;
			margin-bottom:35px;
			text-transform: uppercase;
		}
		input {
			width:calc(33.33% - 10px);
			@media(max-width:900px) {
				width:100%;
			}
			&[name="email_txt"] {
				margin:0 15px;
				@media(max-width:900px) {
					margin:0 0 15px;
				}
			}
		}
	}
}

.form-details-header {
	h4 {
		font-size: 17px;
		color: #FC5F92;
		letter-spacing: 0;
		margin:0 0 5px;
	}
	p {
		font-size: 14px;
		color: #75718F;
		letter-spacing: 0;
		line-height: 1.5;
		margin:0 0 15px;
	}
}

.inline-form-wrap {
	background: #F0F0F0;
	padding:75px 0;
	text-align:center;
	h4 {
		font-size:16px;
		color:#4A4A4A;
		font-weight:600;
		text-transform: uppercase;
		@media(max-width:900px) {
			font-size:15px;
		}
		@media(max-width:750px) {
			font-size:14px;
		}
	}
	h2 {
		font-size:32px;
		line-height: 1.25em;
		font-weight:700;
		text-transform: uppercase;
		@media(max-width:900px) {
			font-size:29px;
		}
		@media(max-width:750px) {
			font-size:27px;
		}
		@media(max-width:600px) {
			font-size:24px;
		}
		@media(max-width:450px) {
			font-size:22px;
		}
	}
	form {
		margin:35px 0 0;
		input[name="name_txt"],
		input[name="email_txt"],
		input[name="telephone_txt"] {
			width:calc(33% - 15px);
			@media(max-width:750px) {
				width:100%;
				margin:0;
			}
		}
		input[name="email_txt"] {
			margin-left:22.5px;
			margin-right:22.5px;
			@media(max-width:750px) {
				margin-left:0;
				margin-right:0;
				margin-top:10px;
				margin-bottom:10px;
			}
		}
		button {
			display: inline-block;
			width:auto;
			font-weight:500;
			text-transform: capitalize;
			font-size:16px;
			margin-top:20px;
		}
	}
}

.white-bg {
	.inline-form-wrap {
		background:#fff;
	}
}

.fancybox-inner {
	h3 {
		padding:25px 25px 0;
		font-size: 23px;
		color: #333333;
		font-weight:700;
		margin-bottom:25px;
	}
	fieldset {
		&:nth-child(1),
		&:nth-child(2) {
			padding:0 25px;
		}
	}
	#loader {
		display: none;
		background-color:#fff;
	}
	.response {
		text-align:center;
		font-size:16px;
		display: block;
		margin:50px 25px;
		font-style: italic;
	}
}
