.about-cols-wrap {
  display:flex;
  padding:65px 0 80px;
  background: url(../imgs/line.png) top repeat-x;
  @media(max-width:900px) {
    padding:35px 0 50px;
  }
  @media(max-width:600px) {
    padding:15px 0 0px;
  }
  @media(max-width:450px) {
    padding:0;
  }
  .col {
    flex-grow: 1;
    .title-wrap {
      margin:15px 0 30px;
    }
    p {
      margin-top:1.5em;
      &:nth-child(1) {
        margin-top:0;
      }
    }
    &:nth-child(1) {
      width:calc(50% - 80px);
    }
    &:nth-child(2) {
      width:calc(50%);
      margin-left:80px;
    }
  }
  &:nth-child(even) {
    .col {
      &:nth-child(1) {
        order:3;
        margin-left:80px;
      }
      &:nth-child(2) {
        margin-left:0;
      }
    }
  }
  &:nth-child(1) {
    background:none;
  }
  &#list13 {
    .col {
      .txt-wrap {
        column-count: 2;
        column-gap: 100px;
        @media(max-width:960px) {
          column-gap: 65px;
        }
        @media(max-width:600px) {
          column-count: 1;
        }
      }
    }
  }
  @media(max-width:900px) {
    flex-direction: column;
    .col {
      &:nth-child(1) {
        width:calc(100%);
        margin-bottom:50px;
        @media(max-width:600px) { margin-bottom:35px; }
        @media(max-width:450px) { margin-bottom:20px; }
      }
      &:nth-child(2) {
        width:calc(100%);
        margin-left:0;
        margin-bottom:50px;
        @media(max-width:600px) { margin-bottom:35px; }
        @media(max-width:450px) { margin-bottom:20px; }
      }
    }
    &:nth-child(even) {
      .col {
        &:nth-child(1) {
          order:0;
          margin-left:0;
        }
        &:nth-child(2) {
          margin-left:0;
        }
      }
    }
  }
}

#list11 {
  padding: 0 0 90px;
}
